import type { ApiSistema } from 'features/sistema/types';
import { newRequest } from 'std/api/newRequest';

export type ApiFintechTermosDeUsoGetParams = {
    empresa_idpk: number;
    empresa_documento: string;
    url: string;
};

type ApiFintechTermosDeUsoGet = {
    sistema: Partial<ApiSistema>;
    fintech: ApiFintechTermosDeUsoGetFintech;
    status: 'sucesso';
};

export type ApiFintechTermosDeUsoGetFintech =
    | ApiFintechTermosDeUsoGetFintechS
    | ApiFintechTermosDeUsoGetFintechN;

type ApiFintechTermosDeUsoGetFintechS = {
    fcp_termos_de_uso_aceito: 'S';
    mensagem: string;
};

type ApiFintechTermosDeUsoGetFintechN = {
    fcp_termos_de_uso_aceito: 'N';
    fcp_empresa_idpk: number;
    fcp_empresa_nome: string;
    fcp_cnpj_cpf: string;
    csp_nome: string;
    termos_de_uso: string;
};

export function apiFintechTermosDeUsoGet({
    params,
}: { params: ApiFintechTermosDeUsoGetParams }): Promise<ApiFintechTermosDeUsoGet> {
    return newRequest({
        token: false,
        url: 'Fintech/TermosDeUso',
        type: 'get',
        params,
    });
}

export function apiFintechTermosDeUsoPut({
    params,
    body,
}: {
    params: {
        empresa_idpk: number;
        empresa_documento: string;
    };
    body: {
        geo_latitude: string;
        geo_longitude: string;
        geo_ip: string;
        geo_sistema_operacional: string;
        geo_sistema_operacional_versao: string;
    };
}): Promise<{ status: 'sucesso'; mensagem: string }> {
    return newRequest({
        token: false,
        url: 'Fintech/TermosDeUso',
        type: 'put',
        params,
        body,
    });
}
