import { Drawer } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { memo, useEffect } from 'react';
import { MenuLeft } from './MenuLeft/MenuLeft';
import style from './style.module.scss';

let touchStartPrev = 0;
let touchStartLocked = false;

function BaseMenu({ isAdmin = false }: { isAdmin?: boolean }) {
    const menuMobile = useLayoutStore((s) => s.menuMobile);
    const menuOpen = useLayoutStore((s) => s.menuOpen);

    useEffect(() => {
        // mobile menu touch slide opener
        const unify = (e: TouchEvent) => (e.changedTouches ? e.changedTouches[0] : undefined);

        document.addEventListener(
            'touchstart',
            (e) => {
                const event = unify(e) || e;
                const x = 'clientX' in event ? event.clientX : 0;
                touchStartPrev = x;
                touchStartLocked = x > 70;
            },
            { passive: false },
        );

        document.addEventListener(
            'touchmove',
            (e) => {
                const event = unify(e) || e;
                const x = 'clientX' in event ? event.clientX : 0;
                const prev = touchStartPrev;
                if (x - prev > 50 && !touchStartLocked) {
                    toggleMobileMenu();
                    touchStartLocked = true;
                }
            },
            { passive: false },
        );
    }, []);

    const toggleMobileMenu = (): void => {
        useLayoutStore.setState((s) => ({ menuOpen: !s.menuOpen }));
    };

    if (menuMobile) {
        return (
            <div>
                <div
                    className={style.handler}
                    onClick={toggleMobileMenu}
                    onFocus={(e) => {
                        e.preventDefault();
                    }}
                    onKeyPress={toggleMobileMenu}
                    // biome-ignore lint/a11y/useSemanticElements: <explanation>
                    role='button'
                    tabIndex={0}
                >
                    <div className={style.handlerIcon} />
                </div>
                <Drawer
                    closable={false}
                    open={menuOpen}
                    placement='left'
                    rootClassName={style.mobileMenu}
                    onClose={toggleMobileMenu}
                    maskClosable={true}
                    getContainer={undefined}
                    width={256}
                >
                    <MenuLeft isAdmin={isAdmin} />
                </Drawer>
            </div>
        );
    }

    return <MenuLeft isAdmin={isAdmin} />;
}

export const Menu = memo(BaseMenu);
