import { createFileRoute } from '@tanstack/react-router';
import { useLoginAdminStore } from 'features/login-admin/store';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/admin/_a/representantes')({
    beforeLoad: () => {
        const tipo = useLoginAdminStore.getState().loginAdmin?.representante?.tipo;
        const isPrincipal = tipo === 'Principal';
        return validateRoute({
            podeExibirFintech: true,
            condicao: isPrincipal,
        });
    },
});
