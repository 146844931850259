import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'std/const/date';
import { useLoginAdminStore } from './store';

export function getTokenAdminStatus(): 'valid' | 'about-to-expire' | 'expired' {
    const token_exp = useLoginAdminStore.getState().loginAdmin?.token_exp;

    if (!token_exp) {
        return 'expired';
    }

    const now = dayjs();
    const tokenExp = dayjs(token_exp, DATE_TIME_FORMAT);
    const diff = tokenExp.diff(now, 'minutes');

    if (diff <= 0) {
        return 'expired';
    }

    // Faltando 5 horas renovar o token
    if (diff <= 5 * 60) {
        return 'about-to-expire';
    }

    return 'valid';
}
