import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin/_a/configuracao/representante/')({
  validateSearch: (
    search: Record<string, unknown>,
  ): {
    tab?: string;
    menu?: string;
  } => {
    return {
      tab: String(search?.tab || ''),
      menu: String(search?.menu || ''),
    };
  },
});
