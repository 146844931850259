import { z } from 'zod';

export enum VendaFormaPagamentoAntecipadoStatus {
    Desconhecido = 0,
    Aguardando = 1,
    Pago = 2,
    Estornado = 3,
}

// TODO
export const vendaFormaPagamentoAntecipadoSchema = z.object({
    status: z.nativeEnum(VendaFormaPagamentoAntecipadoStatus),
    cartao: z.any().nullish(),
    pix: z.any().nullish(),
});
