import { Grid } from 'antd';

type ScreenAliases = {
    isSmallestMobile?: boolean;
    isSmallerThanTablet?: boolean;
    isTabletScreen?: boolean;
    isSmallDesktop?: boolean;
    isDesktop?: boolean;
    isSmallerThan4k?: boolean;
};

export function useScreenAliases(): ScreenAliases {
    const { xs, sm, md, lg, xl, xxl } = Grid.useBreakpoint();

    const isSmallestMobile = xs && !sm;
    const isSmallerThanTablet = (xs || sm) && !md;
    const isTabletScreen = !xs && sm && md && !lg;
    const isSmallDesktop = md && lg && !xl;
    const isDesktop = lg || xl || xxl;
    const isSmallerThan4k = !(xl || xxl);

    return {
        isSmallestMobile,
        isTabletScreen,
        isSmallDesktop,
        isSmallerThanTablet,
        isDesktop,
        isSmallerThan4k,
    };
}
