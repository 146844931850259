import { useLoginStore } from 'features/login/store';
import { router } from 'router/Router';
import { isTokenValid } from 'std/token';
import { zodParse } from 'std/zod';
import type { z } from 'zod';
import { type BuildUrlParams, buildUrl } from './buildUrl';
import { API_URL } from './const';
import type { ApiResponse, Method } from './types';

export async function fetchApi<
    Body extends object,
    Schema extends z.AnyZodObject | undefined,
    Data = Schema extends z.AnyZodObject ? z.infer<Schema> : ApiResponse,
>({
    token = true,
    endpoint,
    method,
    idpk,
    params,
    body,
    schema,
    timeout,
}: {
    /** Se true, vai enviar o bearer token e empresa_idpk */
    token?: boolean;
    /** Ex: Cliente/Consultar */
    endpoint: string;
    /** Tipo da requisição HTTP */
    method: Method;
    /** Idpk para ser incluído na url */
    idpk?: number | string;
    /** Parâmetros. Se token = true, será enviado o idpk da empresa. */
    params?: BuildUrlParams;
    body?: Body;
    schema?: Schema;
    /** Tempo máximo para realizar a requisição */
    timeout?: number;
}): Promise<Data> {
    const renovandoToken = useLoginStore.getState().renovandoToken;
    if (renovandoToken) {
        throw new Error('Renovando token...');
    }

    const innerParams = { ...params };
    const init: RequestInit = {
        headers: { 'Content-Type': 'application/json' },
        method,
        body: JSON.stringify(body),
    };

    if (timeout) {
        init.signal = AbortSignal.timeout(timeout);
    }

    // dentro de um teste o pathname vai ser /
    if (token && window.location.pathname !== '/') {
        if (isTokenValid()) {
            init.headers = {
                Authorization: `Bearer ${useLoginStore.getState()?.login?.token}`,
                'Content-Type': 'application/json',
            };

            innerParams.empresa_idpk = useLoginStore.getState().empresaIdpk;
        } else if (window.location.pathname.includes('/a/')) {
            useLoginStore.getState().clearLogin();
            router.navigate({ to: '/auth/login' });
        } else {
            // biome-ignore lint/suspicious/noConsole: <explanation>
            console.error('Rota pública solicitando token', { endpoint, method });
        }
    }

    const url = `${API_URL}/${buildUrl(endpoint, innerParams, idpk)}`;
    const response = await fetch(url, init);
    const data = await response.json();
    throwIfResponseIsNotValid(data, method);

    if (schema) {
        return zodParse(schema, data) as Data;
    }

    return data;
}

function throwIfResponseIsNotValid<Data extends ApiResponse>(data: Data, method: Method): void {
    if (!data) {
        throw new Error('Conexão falhou ou servidor está indisponível.');
    }

    if (data.status === 'erro') {
        const error = data.mensagem || data.mensagem_original || JSON.stringify(data);
        throw new Error(error.replaceAll("'", ''));
    }

    // Ao realizar um post, pode ser que algum registro falhou ao ser inserido
    if (method !== 'post') {
        return;
    }

    if (Array.isArray(data.registros)) {
        for (const registro of data.registros) {
            if (
                'status' in registro &&
                registro.status === 'erro' &&
                'message' in registro &&
                typeof registro.message === 'string'
            ) {
                throw new Error(registro.message);
            }
        }
    }
}
