import { z } from 'zod';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

const AutoLoginRedirectOptionsSchema = z.object({
    tipo_pix: z.enum(['instantaneo', 'cobranca']).nullish(),
    data_inicio: z.string().nullish(),
    data_fim: z.string().nullish(),
});

export const AutoLoginRedirectSchema = z.object({
    email: z.string(),
    senha: z.string(),
    empresa_idpk: z.number().nullish(),
    redirect: z.string(),
    redirect_idpk: z.number().nullish(),
    redirect_options: AutoLoginRedirectOptionsSchema.nullish(),
});

export type AutoLoginRedirect = z.infer<typeof AutoLoginRedirectSchema>;

type AutoLoginRedirectStore = Partial<AutoLoginRedirect> & {
    clear: () => void;
};

export const useAutoLoginRedirectStore = create(
    persist(
        devtools<AutoLoginRedirectStore>(
            (set) => ({
                clear: () => {
                    set({});
                },
            }),
            { name: 'auto-login-redirect' },
        ),
        {
            name: 'auto-login-redirect',
        },
    ),
);
