import type { PartialUndefinedOrNull } from 'std/types/partial';
import { z } from 'zod';

export enum FormaPagamentoStatus {
    Ativo = 1,
    Inativo = 2,
}

export enum FormaPagamentoClassificacao {
    Desconhecido = 0,
    Dinheiro = 1,
    Cheque = 2,
    CartaoCredito = 3,
    CartaoDebito = 4,
    ContaReceber = 5,
    ContaPagar = 6,
    PixContaBancaria = 7,
    Vale = 10,
    Boleto = 15,
    Deposito = 16,
    PixContaDigital = 17,
    Transferencia = 18,
    Cashback = 19,
    PixCobranca = 66,
    CartaoCreditoCobrancaOnline = 77,
    SemPagamento = 90,
    Outros = 99,
}

export const FormaPagamentoSchema = z.object({
    fop_idpk: z.number(),
    fop_empresa_idpk: z.number(),
    fop_status: z.nativeEnum(FormaPagamentoStatus),
    fop_descricao: z.string(),
    fop_classificacao: z.nativeEnum(FormaPagamentoClassificacao),
    fop_numero_maximo_parcelas: z
        .number()
        .nullable()
        .transform((v) => v ?? 0),
    fop_dias_intervalo: z
        .number()
        .nullable()
        .transform((v) => v ?? 0),
    fop_financeiro_conta_idpk: z.number().nullable(),
    fop_ultima_alteracao: z.string().nullable(),
});

export type FormaPagamento = z.infer<typeof FormaPagamentoSchema>;

export type FormaPagamentoBody = PartialUndefinedOrNull<
    Omit<FormaPagamento, 'fop_idpk' | 'fop_empresa_idpk' | 'fop_ultima_alteracao'>
>;
