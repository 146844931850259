import type { ColumnType, SortOrder } from 'antd/lib/table/interface';
import { z } from 'zod';

export type TipoDesconto = '$' | '%';
export type SimNao = 'S' | 'N';
export enum SimNaoNumber {
    S = 1,
    N = 0,
}

/** Indica que o objeto passado vai ser alterado dentro da função, sem clonar */
export type Mutable<T> = T;

export type BasicSelectOption = {
    nome: string;
    idpk: number;
};

export type SelectOption = {
    value: number;
    label: string;
};

/** Substitui o evento de scroll por falta de algumas propriedades */
export interface ScrollEvent extends Omit<React.UIEvent<HTMLDivElement>, 'target'> {
    target: {
        scrollTop: number;
        offsetHeight: number;
        scrollHeight: number;
        /* caso precisar adicionar mais propriedades aqui */
    };
}

/** Usado na listagem de tabelas para filtrar pelas colunas */
export type TableSort = {
    sortColumnKey?: React.Key;
    sortOrder?: SortOrder;
};

/** Usado em totalizadores: Contas a receber/pagar, cobrança pix... */
export type ValorTotalItem = {
    status: string;
    valor_total: number;
    quantidade_total: number;
    situacao?: number;
};

/** Propriedade editable está faltando no tipo da coluna do antd */
export interface TableColumn<T> extends ColumnType<T> {
    editable?: boolean;
}

const ImagemBase64Schema = z.object({
    imagem_nome: z.string(),
    imagem_base64: z.string(),
});

export type ImagemBase64 = z.infer<typeof ImagemBase64Schema>;

export const FileBase64Schema = z.object({
    file_name: z.string(),
    file_base64: z.string(),
});

export type FileBase64 = z.infer<typeof FileBase64Schema>;

export type DeepReadonly<T> = T extends (infer U)[]
    ? readonly DeepReadonly<U>[]
    : T extends object
      ? { readonly [K in keyof T]: DeepReadonly<T[K]> }
      : T;
