import type { ApiFinanceiroContaFintech } from 'features/conta-digital/types/ApiFinanceiroContaFintech';
import { TipoPessoa } from 'features/pessoa/enum';
import type { SimNao } from 'std/types';
import { AmbienteFiscal } from 'std/types/enum';
import { z } from 'zod';
import {
    EmpresaCrtCrt,
    EmpresaIndicadorIE,
    EmpresaMicro,
    EmpresaPermiteCredito,
    EmpresaSistemaTipoUso,
    EmpresaSituacao,
    EmpresaTipoEmissao,
} from './enum';

const EmpresaCrtSchema = z.object({
    crt_idpk: z.number(),
    crt_crt: z.nativeEnum(EmpresaCrtCrt),
    crt_descricao: z.string(),
});

const EmpresaCidadeSchema = z.object({
    pais: z.string(),
    cid_uf: z.string(),
    cid_ibge: z.string(),
    cid_cidade: z.string(),
    cid_timezone: z.string(),
    cid_pais_idpk: z.number(),
    cid_idpk: z.number().optional(),
    cid_ultima_alteracao: z.string().optional(),
});

const EmpresaSuporteSchema = z.object({
    esu_empresa_idpk: z.number(),
    esu_suporte_ativo: z.enum(['S', 'N']),
    esu_expiracao: z.string().nullable(),
});

export type EmpresaSuporte = z.infer<typeof EmpresaSuporteSchema>;

// TODO: Criar schemas que estão faltando
export const EmpresaSchema = z.object({
    crt: EmpresaCrtSchema.nullable(),
    cidade: EmpresaCidadeSchema.nullable(),
    fintech: z.custom<ApiFinanceiroContaFintech>((value) => value).nullable(),
    suporte: EmpresaSuporteSchema.nullable(),
    contador: z.custom<ApiEmpresaContador>((value) => value).nullable(),
    empresa_slug: z.custom<ApiEmpresaSlug>((value) => value).nullable(),
    representante: z.custom<ApiEmpresaRepresentante>((value) => value).nullable(),
    ramo_atividade: z.custom<ApiEmpresaRamoAtividade>((value) => value).nullable(),

    emp_idpk: z.number(),
    emp_sistema_idpk: z.number(),
    emp_sistema_tipo_uso: z.nativeEnum(EmpresaSistemaTipoUso),
    emp_situacao: z.nativeEnum(EmpresaSituacao),
    emp_tipo_pessoa: z.nativeEnum(TipoPessoa),
    emp_financeiro_juros_mes: z.number(),
    emp_financeiro_multa: z.number(),
    // caso o vencimento + carência cair no sábado, se estiver marcado nao gerar multa para pagamento ate Segunda
    emp_financeiro_sabado: z.number(),
    // caso o vencimento + carência cair no domingo se estiver marcado nao gerar multa para pagamento ate Segunda
    emp_financeiro_domingo: z.number(),
    emp_fiscal_tipo_emissao: z.nativeEnum(EmpresaTipoEmissao),
    emp_notificacao: z.union([z.literal(0), z.literal(1)]),
    emp_fintech_extrato_temporario: z.number(),
    emp_representante_idpk: z.number().nullable(),
    emp_empresa_representante_idpk: z.number().nullable(),
    emp_financeiro_categoria_venda_idpk: z.number().nullable(),
    emp_cidade_idpk: z.number().nullable(),
    emp_crt_idpk: z.number().nullable(),
    emp_ramoatividade_idpk: z.number().nullable(),
    emp_regra_fiscal_idpk: z.number().nullable(),
    emp_data_bloqueio: z.string().nullable(),
    emp_microempresa: z.nativeEnum(EmpresaMicro).nullable(),
    emp_responsavel: z.string().nullable(),
    emp_data_nascimento_abertura: z.string().nullable(),
    emp_nome_razaosocial: z.string().nullable(),
    emp_nome_fantasia: z.string().nullable(),
    emp_cnpj_cpf: z.string().nullable(),
    emp_ie_rg: z.string().nullable(),
    emp_ie_rg_indicador: z.nativeEnum(EmpresaIndicadorIE).nullable(),
    emp_im: z.string().nullable(),
    emp_rg_orgao: z.string().nullable(),
    emp_rg_estado: z.string().nullable(),
    emp_rg_emissao: z.string().nullable(),
    emp_cnae: z.string().nullable(),
    emp_logotipo_dataenvio: z.string().nullable(),
    emp_logotipo_foto: z.string().nullable(),
    emp_doc_estrangeiro: z.string().nullable(),
    emp_logradouro: z.string().nullable(),
    emp_logradouro_numero: z.string().nullable(),
    emp_bairro: z.string().nullable(),
    emp_complemento: z.string().nullable(),
    emp_telefone_principal: z.string().nullable(),
    emp_telefone_secundario: z.string().nullable(),
    emp_email: z.string().nullable(),
    emp_certificado_dataenvio: z.string().nullable(),
    emp_certificado: z.string().nullable(),
    emp_certificado_senha: z.string().nullable(),
    emp_certificado_validade: z.string().nullable(),
    emp_certificado_CSC: z.string().nullable(),
    emp_certificado_CSC_id: z.string().nullable(),
    emp_cep: z.string().nullable(),
    emp_financeiro_carencia_dias: z.number().nullable(),
    // caso o vencimento + carência cair no feriado nacional se estiver marcado nao gerar multa para pagamento no próximo dia util
    emp_financeiro_feriado: z.number().nullable(),
    emp_fiscal_ambiente: z.nativeEnum(AmbienteFiscal).nullable(),
    emp_chave_cadastro: z.string().nullable(),
    emp_webhook_padrao: z.string().nullable(),
    emp_webhook_token: z.string().nullable(),
    emp_imposto_permitecredito: z.nativeEnum(EmpresaPermiteCredito).nullable(),
    emp_imposto_icmscredito: z.number().nullable(),
    emp_imposto_funrural_aliquota: z.number().nullable(),
    emp_imposto_funrural_descontar: z.number().nullable(),
    emp_ultima_alteracao: z.string().nullable(),
});

export type Empresa = z.infer<typeof EmpresaSchema>;

export type ApiEmpresaContador = {
    emc_empresa_idpk: number;
    emc_relacao_envio_automatico: SimNao;
    emc_relacao_envio_dia: number;
    emc_relacao_envio_xml: SimNao;
    emc_relacao_envio_relacao_fiscal: SimNao;
    emc_relacao_envio_relacao_financeira: SimNao;
    emc_autorizar_autxml: SimNao;
} & Partial<{
    emc_documento: string;
    emc_razaosocial: string;
    emc_logradouro: string;
    emc_logradouro_numero: string;
    emc_logradouro_complemento: string;
    emc_bairro: string;
    emc_cep: string;
    emc_cidade_idpk: number;
    emc_cidade: string;
    emc_uf: string;
    emc_ibge: string;
    emc_fone: string;
    emc_email: string;
    emc_contabilista_nome: string;
    emc_contabilista_documento: string;
    emc_contabilista_crc: string;
}>;

export enum EmpresaSlugCatalogoExibirPreco {
    NaoExibir = 1,
    PrecoProduto = 2,
    PrecoTabela = 3,
}

export enum EmpresaSlugCatalogoTipoUso {
    /** Somente visualização dos produtos */
    SomenteVisualizacao = 1,
    /** Visualização dos produtos, realização de pedidos e compartilhamento no whatsapp */
    VisualizacaoPedidosCompartilhamento = 2,
    /** Visualização dos produtos, realização de pedidos e checkout de vendas */
    VisualizacaoPedidoVenda = 3,
}

export type ApiEmpresaSlug = {
    ems_empresa_idpk: number;
    ems_sistema_idpk: number;
    ems_cliente_cobranca_ativa: SimNao;
} & Partial<{
    ems_cor_padrao: string;
    ems_slug: string;
    ems_descricao_titulo: string;
    ems_descricao_texto: string;
    ems_catalogo_ativo: SimNao;
    ems_catalogo_exibir_preco: EmpresaSlugCatalogoExibirPreco;
    ems_catalogo_tipo_uso: EmpresaSlugCatalogoTipoUso;
    ems_tabela_preco_idpk: number;
    ems_consulta_cliente_ativa: SimNao;
    ems_whatsapp: string;
    ems_instagram: string;
    ems_facebook: string;
    ems_youtube: string;
    ems_twitter: string;
    ems_linkedin: string;
}>;

type ApiEmpresaRepresentante = Partial<{
    rep_idpk: number;
    rep_nome: string;
    rep_documento: string;
    rep_suporte_email: string;
    rep_suporte_telefone: string;
    rep_suporte_whatsapp: string;
    rep_suporte_link_acesso_remoto: string;
}>;

export type ApiEmpresaRamoAtividade = {
    era_idpk: number;
    era_tipo: string;
    era_ramointerno: number;
};
