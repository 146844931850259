import { notification } from 'antd';
import { apiLoginRenovarToken } from 'features/login/api/apiLoginRenovarToken';
import { router } from 'router/Router';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import type { ApiLoginAdmin } from './api/apiLoginAdmin';

type LoginAdminStore = {
    loginAdmin: ApiLoginAdmin | undefined;
    acessoNegadoMessage: string | undefined;
    codigoVerificado: boolean;
    renovandoToken: boolean;

    renovarToken: () => Promise<void>;
    clearLogin: () => void;
};

export const useLoginAdminStore = create<LoginAdminStore>()(
    devtools(
        persist(
            (set, get) => ({
                loginAdmin: undefined,
                acessoNegadoMessage: undefined,
                codigoVerificado: false,
                renovandoToken: false,

                renovarToken: async () => {
                    const { loginAdmin, renovandoToken } = get();

                    if (renovandoToken) {
                        return;
                    }

                    set({ renovandoToken: true });

                    try {
                        const response = await apiLoginRenovarToken();

                        set({
                            loginAdmin: loginAdmin
                                ? {
                                      ...loginAdmin,
                                      token: response.token,
                                      token_exp: response.token_exp,
                                  }
                                : undefined,
                            renovandoToken: false,
                        });

                        window.location.reload();
                    } catch (e) {
                        notification.error({
                            message: 'Falha ao renovar token',
                            description: e.message,
                        });

                        set({
                            loginAdmin: undefined,
                            renovandoToken: false,
                        });

                        router.navigate({ to: '/admin/auth/login' });
                    }
                },

                clearLogin: () => {
                    set({ loginAdmin: undefined });
                },
            }),
            {
                name: 'loginAdmin',
                partialize: (state) => ({
                    loginAdmin: state.loginAdmin,
                }),
            },
        ),
        { name: 'loginAdmin' },
    ),
);
