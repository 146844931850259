import type { AxiosRequestConfig } from 'axios';
import { axiosWithoutToken } from 'std/api/axios';

export async function semTokenGet(url: string, config?: AxiosRequestConfig): Promise<any> {
    return axiosWithoutToken
        .get(url, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}
