import { useLoginAdminStore } from 'features/login-admin/store';
import { useMemo } from 'react';
import type { MenuData } from './types';

export function useGetMenuDataAdmin(): MenuData[] {
    const data: MenuData[] = useMemo(
        () => [
            {
                title: 'Acesso rápido',
                key: 'acesso-rapido',
                icon: 'fe fe-shopping-cart',
                children: [
                    {
                        title: 'Representantes',
                        key: 'representantes',
                        url: '/admin/representantes',
                        hide:
                            useLoginAdminStore.getState().loginAdmin?.representante?.tipo !==
                            'Principal',
                    },
                    {
                        title: 'Empresas',
                        key: 'empresas',
                        url: '/admin/empresas',
                    },
                ],
            },
        ],
        [],
    );

    return data;
}
