import { createFileRoute } from '@tanstack/react-router';
import { $vendaStorePagination } from 'features/venda/page/vendaStorePagination';
import type { VendaTipo } from 'features/venda/types/Venda';

export const Route = createFileRoute('/a/venda/$tipo/')({
    beforeLoad: (ctx) => {
        const tipoParam = ctx.params.tipo as VendaTipo;
        const tipoFilter = $vendaStorePagination.filter?.tipo;

        // Se não tem tipo na store, seta o tipo do param
        if (!tipoFilter) {
            $vendaStorePagination.setFilter({ tipo: tipoParam });
            return;
        }

        // Se o tipo na store for cancelado ou total, ignora
        if (tipoFilter === 'cancelado' || tipoFilter === 'total') {
            return;
        }

        // Se o tipo na store for diferente do param, seta o param
        if (tipoFilter !== tipoParam) {
            $vendaStorePagination.setFilter({ tipo: tipoParam });
        }
    },
});
