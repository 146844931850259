import { redirect } from '@tanstack/react-router';
import type { AnyRouter, RegisteredRouter, RoutePaths, ToPathOption } from '@tanstack/react-router';
import { useLoginAdminStore } from 'features/login-admin/store';
import { getTokenAdminStatus } from 'features/login-admin/util';
import { useLoginStore } from 'features/login/store';
import { getTokenStatus, userHasPermission } from 'features/login/util';
import type { TipoPermissao, UsuarioPermissao } from 'features/usuario/types/UsuarioTipoPermissao';

export type RoutePath<
    TRouter extends AnyRouter = RegisteredRouter,
    TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
    TTo extends string = '',
> = ToPathOption<TRouter, TFrom, TTo> & {};

export function validateRoute({
    podeExibirFintech,
    permissao,
    nivel,
    condicao,
}: {
    podeExibirFintech: boolean;
    permissao?: UsuarioPermissao;
    nivel?: TipoPermissao.Completo | TipoPermissao.Parcial;
    condicao?: boolean;
}) {
    if (condicao === false) {
        throw redirect({
            to: '/',
        });
    }

    const isFintech = useLoginStore.getState().getEmpresa()?.emp_sistema_tipo_uso === 'F';

    if (isFintech && !podeExibirFintech) {
        throw redirect({
            to: '/',
        });
    }

    if (permissao && nivel && !userHasPermission(permissao, nivel)) {
        throw redirect({
            to: '/',
        });
    }
}

// Validações se o usuário está logado no Painel Administrativo
export function goToLoginAdmin(): boolean {
    const { loginAdmin: login } = useLoginAdminStore.getState();
    const isUserLogged = login !== undefined;
    const isAuth = location.pathname.startsWith('/admin/auth/');

    // Se não estiver logado, mas estiver numa tela de autenticação não fazer nada
    if (!isUserLogged && isAuth) {
        return false;
    }

    // Se não estiver logado manda pro login
    if (!isUserLogged) {
        return true;
    }

    const tokenStatus = getTokenAdminStatus();

    // Se o token estiver expirado, envia pro login caso não estiver numa tela auth
    if (tokenStatus === 'expired') {
        return !isAuth;
    }

    // Se token estiver prestes a expirar, realizar renovação
    if (tokenStatus === 'about-to-expire') {
        useLoginAdminStore.getState().renovarToken();
    }

    return false;
}


// Validações se o usuário está logado
export function goToLogin(): boolean {
    const { login, empresaIdpk } = useLoginStore.getState();
    const isUserLogged = login !== undefined && empresaIdpk !== undefined;
    const isAuth = location.pathname.startsWith('/auth/');

    // Se não estiver logado, mas estiver numa tela de autenticação não fazer nada
    if (!isUserLogged && isAuth) {
        return false;
    }

    // Se não estiver logado manda pro login
    if (!isUserLogged) {
        return true;
    }

    const tokenStatus = getTokenStatus();

    // Se o token estiver expirado, envia pro login caso não estiver numa tela auth
    if (tokenStatus === 'expired') {
        return !isAuth;
    }

    // Se token estiver prestes a expirar, realizar renovação
    if (tokenStatus === 'about-to-expire') {
        useLoginStore.getState().renovarToken();
    }

    return false;
}
