type Ok<T> = { ok: true; err: false; value: T };
type Err<E> = { ok: false; err: true; error: E };
export type Result<T, E> = Ok<T> | Err<E>;
export type AsyncResult<T, E> = Promise<Result<T, E>>;

export function ok<T>(value: T): Ok<T> {
    return { ok: true, err: false, value };
}

export function err<E>(error: E): Err<E> {
    return { ok: false, err: true, error };
}

export function match<T, U, V, E extends Error>(
    result: Result<T, E>,
    obj: {
        ok: (value: T) => U;
        err: (error: E) => V;
    },
): U | V {
    if (result.err) {
        return obj.err(result.error);
    }

    return obj.ok(result.value);
}

export function safeTry<T>(fn: () => T): Result<T, Error> {
    try {
        return ok(fn());
    } catch (error: unknown) {
        if (error instanceof Error) {
            return err(error);
        }

        if (typeof error === 'string') {
            return err(new Error(error));
        }

        return err(new Error('Unknown error'));
    }
}

export async function safeTryAsync<T>(fn: () => Promise<T>): AsyncResult<T, Error> {
    try {
        return ok(await fn());
    } catch (error) {
        if (error instanceof Error) {
            return err(error);
        }

        if (typeof error === 'string') {
            return err(new Error(error));
        }

        return err(new Error('Unknown error'));
    }
}
