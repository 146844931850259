import { browserTracingIntegration, init, replayIntegration } from '@sentry/react';
import { apiInternoErro } from 'features/erro/api/apiInternoErroPost';
import { useLayoutStore } from 'features/layout/store';
import { roundAbnt } from 'std/math';
import { isSandbox } from 'std/url';

export function initBeforeReact(): void {
    clearOld();
    addToProto();
    registerEventsListeners();
    initSentry();
    checkScren();
}

function clearOld(): void {
    if (localStorage.getItem('accessToken')) {
        localStorage.clear();
    }

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (const registration of registrations) {
                registration.unregister();
            }
        });
    }
}

function addToProto(): void {
    Number.prototype.roundAbnt = function (decimalPlaces = 2): number {
        return roundAbnt(this.valueOf(), decimalPlaces);
    };
}

const ignoredErrors = [
    'Invariant failed', // Esse erro ocorre quando o vite tenta fazer HMR e o router se perde
    'Sua solicitação possui um texto não permitido', // SQL injection
    'Failed to fetch',
    "Cannot read properties of null (reading 'print')",
    'Load failed',
];

function containsIgnoredError(errorMessage: string): boolean {
    return ignoredErrors.some((error) => errorMessage.includes(error));
}

function registerEventsListeners(): void {
    window.onerror = async (
        event,
        source,
        lineno,
        colno,
        error?: Error & { hasBeenCaught?: boolean },
    ) => {
        if (error?.message.includes('Failed to fetch dynamically imported module')) {
            window.location.reload();
            return;
        }

        if (
            !error ||
            error.hasBeenCaught ||
            containsIgnoredError(error.message) ||
            error.stack?.includes('recoverFromConcurrentError')
        ) {
            return;
        }

        error.hasBeenCaught = true;
        const message = `${JSON.stringify(event)} | ${error.message}`.slice(0, 10000);

        try {
            await apiInternoErro({
                type: 'EXCEPTION',
                message,
                source,
                lineno,
                colno,
                stack: error.stack?.split('\n').slice(0, 3),
            });
        } catch {
            //
        }
    };

    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
        throw new Error(event.reason);
    };

    window.addEventListener('resize', checkScren);
}

function checkScren() {
    function isFullScreen() {
        return (
            // @ts-ignore - existe
            window.fullScreen ||
            (window.innerWidth === screen.width && window.innerHeight === screen.height)
        );
    }

    useLayoutStore.setState({ isFullscreen: isFullScreen() });

    if (window.location.pathname.startsWith('/a/pdv')) {
        useLayoutStore.setState({ menuOpen: false, menuMobile: true });
        return;
    }

    useLayoutStore.setState({
        menuOpen: window.innerWidth >= 1024,
        menuMobile: window.innerWidth < 768,
    });
}

function initSentry(): void {
    // Initialize sentry if production
    if (!isSandbox()) {
        init({
            dsn: 'https://45bfdaa1ee3b4784801e4cb13da79d16@o4507385320636416.ingest.us.sentry.io/4508298512105472',
            integrations: [
                browserTracingIntegration({
                    traceFetch: false,
                    traceXHR: false,
                }),
                replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}
